import { ObserveVisibility } from 'vue-observe-visibility';
import { isInViewport } from '@/node_modules/@osp/utils/src/is-in-viewport';
import { MediaLoading } from '@/node_modules/@osp/design-system/types/media';
import { ImageOptimizationSettings } from '@/node_modules/@osp/design-system/components/Media/Media.props';
import {
	IRenderSizingMixin,
	RenderSizingMixin,
} from '@/node_modules/@osp/design-system/components/mixins/render-sizing-mixin';
import {
	importRunTask,
	importTimeout,
} from '@/node_modules/@osp/design-system/assets/js/utilities/dynamicImports';
import {
	ILcpOptimizationMixin,
	LcpOptimizationMixin,
} from '@/node_modules/@osp/design-system/components/mixins/lcp-optimization-mixin';
import { IClsBaseMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-base-mixin';
import { ClsContainerMixin } from '@/node_modules/@osp/design-system/components/mixins/cls-container-mixin';
import { Component, Mixins, Prop, Watch } from '~/app-utils/decorators';
import { AbstractComponent } from '~/components/mixins/abstract-component';
import { eecPromotionView } from '~/tracking/events/eec.promotionView';
import {
	PRODUCT_TEASER_NAME,
	TEASER_EXPIRING_SHORTAGE_NAME,
	TEXT_NAME,
	TEXT_PICTURE_TEASER_NAME,
} from '~/@constants/global';
import { useCmsContentStore } from '~/@api/store/cmsContentApi';
import { useServerContextStore } from '~/@api/store/serverContextApi';
import { useServerSettingsStore } from '~/@api/store/serverSettingsApi';
import {
	CmsAbstractTeaserData,
	CmsDereferencedEntry,
	CmsDevices,
	CmsGridLayoutComponent,
	CmsGridLayoutRowLayout,
	CmsProductTeaserData,
	CmsTeaserExpiringShortageData,
	CmsTeaserTheme,
} from '~/@api/store.types';

interface PromotionTrackingData {
	entry: IntersectionObserverEntry;
	rowIndex: number;
}

@Component({
	components: {
		[TEXT_NAME]: () => import('~/components/molecules/content/text-data.vue'),
		[PRODUCT_TEASER_NAME]: () =>
			import('~/components/molecules/content/teaser/product-teaser/product-teaser.vue'),
		[TEXT_PICTURE_TEASER_NAME]: () =>
			import('~/components/molecules/content/teaser/text-picture/text-picture'),
	},
	directives: { ObserveVisibility },
	mixins: [RenderSizingMixin, LcpOptimizationMixin, ClsContainerMixin],
})
export default class GridLayout extends Mixins<
	AbstractComponent & IRenderSizingMixin & ILcpOptimizationMixin
>(AbstractComponent) {
	private content: CmsGridLayoutComponent = null;
	private alreadyTrackedPromotions = [];
	private storedPromotionTrackingData: PromotionTrackingData[] = [];

	@Prop({ required: true })
	public id: string;

	@Prop({ default: 0 })
	public imagePreloadCount: number;

	@Prop({ default: false })
	public preloadLink: boolean;

	@Prop({ default: false })
	public componentPreviewImageOptimization: boolean;

	@Prop({ default: undefined })
	public componentPreviewImageSettings: ImageOptimizationSettings;

	fetch() {
		if (process.client) {
			importRunTask().then(({ runTask }) => {
				runTask(this.loadContent);
			});

			return;
		}

		this.loadContent();
	}

	mounted() {
		(this as unknown as IClsBaseMixin).clsShowRefElement('self', true);
	}

	/**
	 * Calculate the visible data elements per row
	 */
	get layout() {
		let filteredElements = this.filteredElements;
		let nonTextIndex = 0;

		return this.content?.rows?.map((row) => {
			let data;
			let layout;
			let theme;

			if (row.layout === CmsGridLayoutRowLayout.PERCENT_100) {
				data = this.split(filteredElements, 1);
				layout = [this.$style.grid, this.$style.grid100];
				theme = CmsTeaserTheme.LARGE;
			} else if (row.layout === CmsGridLayoutRowLayout.PERCENT_50) {
				data = this.split(filteredElements, 2);
				layout = [this.$style.grid, this.$style.grid50];
				theme = CmsTeaserTheme.MEDIUM;
			} else if (row.layout === CmsGridLayoutRowLayout.PERCENT_33) {
				data = this.split(filteredElements, 3);
				layout = [this.$style.grid, this.$style.grid33];
				theme = CmsTeaserTheme.SMALL;
			} else if (row.layout === CmsGridLayoutRowLayout.PERCENT_25) {
				data = this.split(filteredElements, 4);
				layout = [this.$style.grid, this.$style.grid25];
				theme = CmsTeaserTheme.TINY;
			}

			filteredElements = data.drop;

			const rowData = {
				nonTextIndex,
				layout,
				references: data.take,
				theme,
			};

			nonTextIndex +=
				(rowData.references?.content as CmsGridLayoutComponent)?.datas?.filter(
					(data) => data.key.split('__')[0] !== TEXT_NAME,
				).length || 0;

			return rowData;
		});
	}

	get filteredElements(): CmsDereferencedEntry[] {
		const cmsContentApi = useCmsContentStore(this.$store).api;

		return (
			this.content?.datas
				?.map((data) => cmsContentApi.dereference(data))
				.filter(this.deviceFilter)
				.filter(this.expireShortageFilter) ?? []
		);
	}

	private split(array: any[], length: number): object {
		return {
			drop: array.slice(length),
			take: array.slice(0, length),
		};
	}

	private deviceFilter(element: CmsDereferencedEntry) {
		return ((element?.content as CmsAbstractTeaserData).devices || []).includes(
			useServerContextStore(this.$store).state.userAgent.deviceCategory === 'desktop'
				? CmsDevices.DESKTOP
				: CmsDevices.MOBILE,
		);
	}

	private expireShortageFilter(element: CmsDereferencedEntry) {
		const cmsContentApi = useCmsContentStore(this.$store).api;
		const time: number = Date.now();

		return (
			(((element?.content || {}) as CmsProductTeaserData)?.shortages || [])
				.map((data) => cmsContentApi.dereference(data))
				.filter((entry: CmsDereferencedEntry) => entry?.component === TEASER_EXPIRING_SHORTAGE_NAME)
				.filter((entry: CmsDereferencedEntry) => {
					const content = entry.content as CmsTeaserExpiringShortageData;

					return content.startTime > time || content.endTime < time;
				}).length === 0
		);
	}

	private shouldGeneratePreloadLink(nonTextIndex: number, refIndex: number): boolean {
		return this.preloadLink && nonTextIndex + refIndex < this.imagePreloadCount;
	}

	@Watch('triggerInitialHydration')
	private onTriggerInitialHydration() {
		this.trackStoredTrackingData();
	}

	loadContent() {
		this.content = useCmsContentStore(this.$store).api.element(this.id) as CmsGridLayoutComponent;
	}

	trackStoredTrackingData() {
		this.storedPromotionTrackingData.forEach((storedEntry, entryIndex) => {
			this.trackPromotionView({ ...storedEntry });

			delete this.storedPromotionTrackingData[entryIndex];
		});
	}

	rowVisibilityChanged(isVisible: boolean, entry: IntersectionObserverEntry, rowIndex: number) {
		// Is this element visible in viewport
		if (isVisible) {
			if (this.triggerInitialHydration) {
				this.trackPromotionView({ entry, rowIndex });

				return;
			}

			if (!this.storedPromotionTrackingData.some((data) => data.entry.target === entry.target)) {
				this.storedPromotionTrackingData.push({ entry, rowIndex });
			}
		}
	}

	trackPromotionView(trackingData: PromotionTrackingData) {
		if (this.alreadyTrackedPromotions.includes(trackingData.entry.target)) return;

		// Get teasers for current row (entry.target is the row)
		const teaserTrackingParams = (
			(this.$refs[`teaser-row-${trackingData.rowIndex}`] as Vue[]) || []
		).map((vm: any) => vm.getTrackingParams?.());

		// Do not track empty rows
		if (!teaserTrackingParams.length) {
			return;
		}

		Promise.all([importRunTask(), importTimeout()]).then(async (responses) => {
			const { runTaskWithPromise } = responses[0];
			const { setSafeTimeout } = responses[1];

			let impressionViewIntersectionTimeout = 0;

			await runTaskWithPromise(async () => {
				impressionViewIntersectionTimeout =
					useServerSettingsStore(this.$store).state.settings.tracking
						.impressionViewIntersectionTimeout || 0;

				await Promise.resolve();
			});

			setSafeTimeout(() => {
				if (
					// Check if it is still in the viewport
					isInViewport(trackingData.entry.target as HTMLElement) &&
					// and not already tracking within the timeout
					!this.alreadyTrackedPromotions.includes(trackingData.entry.target)
				) {
					this.alreadyTrackedPromotions.push(trackingData.entry.target);
					eecPromotionView(teaserTrackingParams);
				}
			}, impressionViewIntersectionTimeout);
		});
	}

	getMediaLoading(nonTextIndex: number, refIndex: number): string {
		if (this.shouldGeneratePreloadLink(nonTextIndex, refIndex)) {
			return MediaLoading.EAGER;
		}

		return nonTextIndex + refIndex < this.imagePreloadCount
			? MediaLoading.EAGER
			: MediaLoading.LAZY;
	}
}
